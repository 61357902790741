import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Confetti from 'react-confetti';

import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import AsesorMeeting from './asesorMeeting';
import ReactGA from 'react-ga4'; // Importar ReactGA

export default function Pricing() {
  const [openAsesorMeeting, setOpenAsesorMeeting] = React.useState(false);
  const [showConfetti, setShowConfetti] = React.useState(false);
  const offerRef = React.useRef(null);

  const handleOpenAsesorMeeting = () => setOpenAsesorMeeting(true);
  const handleCloseAsesorMeeting = () => setOpenAsesorMeeting(false);

  const tiers = [
    {
      icon: <BarChartRoundedIcon />,
      title: 'Free',
      price: '0',
      description: [
        '1 informe de prueba',
        'Soporte básico',
      ],
      buttonText: 'Registrate hoy',
      buttonVariant: 'outlined',
      buttonColor: 'primary',
      additionalText: [
        'Ideal para probar ZicoAsis sin costo.',
      ],
      link: 'https://asisapp.zicofy.com/login',
    },
    {
      icon: <AccessTimeRoundedIcon />,
      title: 'Starter',
      subheader: 'Recommended',
      price: '20',
      originalPrice: '40',
      description: [
        'Zicofyers ilimitados',
        '5 informes mensuales',
        'Soporte básico',
      ],
      buttonText: 'Contratar ahora',
      buttonVariant: 'contained',
      buttonColor: 'secondary',
      additionalText: [
        'Perfecto para profesionales independientes y consultorios en crecimiento.',
      ],
      link: 'https://buy.stripe.com/fZe18593O6vZ6OsdQT',
    },
    {
      icon: <SearchRoundedIcon />,
      title: 'Professional',
      price: '30',
      originalPrice: '60',
      description: [
        'Zicofyers ilimitados',
        '10 informes mensuales',
        'Portal con tu marca/nombre',
        'Soporte dedicado',
      ],
      buttonText: 'Contratar ahora',
      buttonVariant: 'contained',
      buttonColor: 'secondary',
      additionalText: [
        'Ideal para profesionales y consultorios avanzados.',
      ],
      link: 'https://buy.stripe.com/8wM8Axa7S4nR3Cg7su',
    },
    {
      icon: <SearchRoundedIcon />,
      title: 'Enterprise',
      price: 'Precio a medida',
      description: [
        'Informes ilimitados',
        'Portal con tu marca/nombre',
        'Dominio propio',
        'Integración a medida',
        'Soporte dedicado',
      ],
      buttonText: 'Contáctanos',
      buttonVariant: 'contained',
      buttonColor: 'secondary',
      additionalText: [
        'Soluciones a medida para grandes equipos y funcionalidades ajustadas a tus necesidades.',
      ],
      onClick: handleOpenAsesorMeeting,
    },
  ];

  const handleButtonClick = (title) => {
    ReactGA.event({
      category: 'Plan',
      action: 'Seleccionó un plan',
      label: title,
    });
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowConfetti(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    const currentOfferRef = offerRef.current;

    if (currentOfferRef) {
      observer.observe(currentOfferRef);
    }

    return () => {
      if (currentOfferRef) {
        observer.unobserve(currentOfferRef);
      }
    };
  }, []);

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Planes a tu medida
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Elige el plan que mejor se ajuste a las necesidades de tu práctica. Desde una prueba gratuita hasta soluciones avanzadas, tenemos opciones flexibles para profesionales en crecimiento y equipos de gran escala. ¡Empieza hoy y lleva tu consulta al siguiente nivel!
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ alignItems: 'stretch', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid item xs={12} sm={6} md={3} key={tier.title}>
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  height: '100%',
                },
                tier.title === 'Professional' &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles('dark', {
                      background:
                        'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                    },
                    tier.title === 'Professional'
                      ? { color: 'grey.100' }
                      : { color: '' },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Professional' && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} size="small" />
                  )}
                </Box>
                <Box
                  sx={[
                    {
                      display: 'flex',
                      alignItems: 'baseline',
                    },
                    tier.title === 'Professional'
                      ? { color: 'grey.50' }
                      : { color: null },
                  ]}
                >
                  {tier.originalPrice ? (
                    <>
                      <Typography
                        component="span"
                        variant="h6"
                        sx={{
                          textDecoration: 'line-through',
                          color: 'text.secondary',
                          mr: 1,
                        }}
                      >
                        €{tier.originalPrice}
                      </Typography>
                      <Typography component="h3" variant="h4">
                        €{tier.price}
                      </Typography>
                    </>
                  ) : (
                    <Typography component="h3" variant="h4">
                      {tier.title === 'Enterprise' ? tier.price : `€${tier.price}`}
                    </Typography>
                  )}
                  {tier.title !== 'Enterprise' && (
                    <Typography component="span" variant="subtitle1">
                      &nbsp; por mes
                    </Typography>
                  )}
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.additionalText.map((line) => (
                  <Typography
                    key={line}
                    variant="body2"
                    component={'p'}
                    sx={[
                      {
                        mb: 1,
                      },
                      tier.title === 'Professional'
                        ? { color: 'grey.50' }
                        : { color: null },
                    ]}
                  >
                    {line}
                  </Typography>
                ))}
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 0.5, display: 'flex', gap: 1, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 16,
                          height: 16,
                        },
                        tier.title === 'Professional'
                          ? { color: 'primary.light' }
                          : { color: 'primary.main' },
                      ]}
                    />
                    <Typography
                      variant="body2"
                      component={'span'}
                      sx={[
                        tier.title === 'Professional'
                          ? { color: 'grey.50' }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color={tier.buttonColor}
                  size="small"
                  onClick={() => {
                    handleButtonClick(tier.title); // Llamar a la función de evento
                    if (tier.title === 'Enterprise') {
                      handleOpenAsesorMeeting();
                    }
                  }}
                  href={tier.title !== 'Enterprise' ? tier.link : undefined}
                  target={tier.title !== 'Enterprise' ? "_blank" : undefined}
                  rel={tier.title !== 'Enterprise' ? "noopener noreferrer" : undefined}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 0 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper
            ref={offerRef}
            elevation={3}
            sx={{
              p: 3,
              textAlign: 'center',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {showConfetti && (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                numberOfPieces={200}
              />
            )}
            <Typography variant="h5" component="h3" gutterBottom>
              🎊 Oferta Especial 🎊
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
              Código: ZICOASISLAUNCH
            </Typography>
            <Typography variant="body1">
              Disfruta de un 50% de descuento al suscribirte
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <AsesorMeeting open={openAsesorMeeting} onClose={handleCloseAsesorMeeting} />
    </Container>
  );
}