import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Gracias from './components/gracias';
import getMPTheme from './theme/getMPTheme';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

ReactGA.initialize('G-W4VGZES98D');

export default function App() {
  const [mode, setMode] = React.useState('light');
  const MPTheme = createTheme(getMPTheme(mode));
  const { setPathPageView, setTrackPageView } = useTrackingCode();

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      setMode('light');
    }

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    setPathPageView(window.location.pathname);
    setTrackPageView();
  }, [setPathPageView, setTrackPageView]);

  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <div>
                <LogoCollection />
                <Features />
                <Divider />
                <Divider />
                <Highlights />
                <Divider />
                <Pricing />
                <Divider />
                <FAQ />
                <Divider />
                <Footer />
              </div>
            </>
          } />
          <Route path="/gracias" element={<Gracias />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}