import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Confetti from 'react-confetti';
import ReactGA from 'react-ga4';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

const Gracias = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'Lead',
      action: 'Lead generado',
      label: 'Interés en ZicoAsis'
    });
  }, []);

  return (
    <StyledBox>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'primary.main' }}>
        ¡Gracias por tu interés en ZicoAsis!
      </Typography>
      <Typography variant="h5" component="p" sx={{ mb: 4, color: 'text.secondary' }}>
        Nos pondremos en contacto contigo muy pronto.
      </Typography>
      <Button variant="contained" color="primary" href="/">
        Volver a la página principal
      </Button>
    </StyledBox>
  );
};

export default Gracias;