import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import zicoasisLogo from '../assets/zicoasisLogo.png';
import ReactGA from 'react-ga4';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '0px 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 8px',
  },
}));

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavClick = (sectionId) => (event) => {
    event.preventDefault();
    scrollToSection(sectionId);
    if (open) {
      setOpen(false);
    }
  };

  const handleLoginClick = () => {
    ReactGA.event({
      category: 'Usuario',
      action: 'Iniciar sesión',
    });
    window.location.href = 'https://asisapp.zicofy.com/login/login/';
  };

  const handleCreateAccountClick = () => {
    ReactGA.event({
      category: 'Usuario',
      action: 'Crear cuenta gratuita',
    });
    window.location.href = 'https://asisapp.zicofy.com/login/register/';
  };

  const isGraciasOr404 = window.location.pathname === '/gracias' || window.location.pathname === '/404';

  return (
    !isGraciasOr404 && (
      <AppBar
        position="fixed"
        sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: { xs: 3, md: 6 } }}
      >
        <Container maxWidth="lg">
          <StyledToolbar variant="dense" disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
              <img src={zicoasisLogo} alt="Zicoasis Logo" style={{ height: '40px', margin: '10px' }} />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
              <Button variant="text" color="info" size="small" onClick={handleNavClick('features')}>
                Características
              </Button>
              <Button variant="text" color="info" size="small" onClick={handleNavClick('pricing')}>
                Precios
              </Button>
              <Button variant="text" color="info" size="small" onClick={handleNavClick('faq')}>
                Preguntas frecuentes
              </Button>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button color="primary" variant="text" size="small" onClick={handleLoginClick}>
                Iniciar sesión
              </Button>
              <Button color="primary" variant="contained" size="small" onClick={handleCreateAccountClick}>
                Crear cuenta gratuita
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  <MenuItem onClick={handleNavClick('features')}>Características</MenuItem>
                  <MenuItem onClick={handleNavClick('pricing')}>Precios</MenuItem>
                  <MenuItem onClick={handleNavClick('faq')}>Preguntas frecuentes</MenuItem>
                  <MenuItem>
                    <Button color="primary" variant="contained" fullWidth onClick={handleCreateAccountClick}>
                      Crear cuenta gratuita
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button color="primary" variant="outlined" fullWidth onClick={handleLoginClick}>
                      Iniciar sesión
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </StyledToolbar>
        </Container>
      </AppBar>
    )
  );
}