import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AsesorMeeting from './asesorMeeting';
import { Document, Page, pdfjs } from 'react-pdf';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import CloseIcon from '@mui/icons-material/Close';
import { motion, AnimatePresence } from 'framer-motion';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

const StyledBox = styled('div')(({ theme, unlocked }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 'auto',
  maxWidth: '800px',
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundSize: 'cover',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  filter: unlocked ? 'none' : 'blur(5px)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  const [openAsesorMeeting, setOpenAsesorMeeting] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [unlocked, setUnlocked] = React.useState(false);
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm();
  const { setIdentity } = useTrackingCode();

  const handleOpenAsesorMeeting = () => setOpenAsesorMeeting(true);
  const handleCloseAsesorMeeting = () => setOpenAsesorMeeting(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenFormDialog = () => setOpenFormDialog(true);
  const handleCloseFormDialog = () => setOpenFormDialog(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPrevPage = () => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  const goToNextPage = () => setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));

  const pageVariants = {
    initial: { opacity: 0, scale: 0.8 },
    in: { opacity: 1, scale: 1 },
    out: { opacity: 0, scale: 0.8 },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/hubspot/contact`, {
        firstName: data.nombre,
        lastName: data.apellido,
        email: data.email,
        phone: data.telefono,
        corriente: data.corriente,
        source: 'landing',
      });

      setUnlocked(true);
      handleCloseFormDialog();
    } catch (error) {
      console.error('Error al crear contacto:', error);
    } finally {
      setLoading(false);
    }
    setIdentity(data.email);
  };
  
  const pdfOptions = React.useMemo(() => ({
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  }), []);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={4}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            IA para psicólogos: Diagnósticos iniciales más<span style={{ color: 'hsl(210, 98%, 48%)' }}> rápidos y precisos</span> 🚀
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
              fontSize: '1rem',
            }}
          >
            Agiliza tus diagnósticos y mejora la precisión en cada consulta. Ahorra tiempo y brinda a tus pacientes resultados más certeros gracias a la inteligencia artificial.
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
              marginBottom: '0rem',
              fontSize: '1rem',
            }}
          >
            ¿Listo para revolucionar tus sesiones?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              pt: 0,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ minWidth: 'fit-content' }}
              onClick={handleOpenAsesorMeeting}
            >
              ¡Habla con un asesor ahora!
            </Button>
          </Box>
        </Stack>
        {!unlocked && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenFormDialog}
            sx={{ mt: 2 }}
          >
            Generar informe de prueba 👇🏻
          </Button>
        )}
        <StyledBox id="pdf-viewer" unlocked={unlocked} sx={{ minHeight: { xs: '400px', sm: '600px' } }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={pageNumber}
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Document 
                file="https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/informePrueba.pdf"
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
                loading={<Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Cargando informe...</Box>}
                options={pdfOptions}
              >
                <Page pageNumber={pageNumber} width={window.innerWidth < 600 ? window.innerWidth - 20 : StyledBox.width} />
              </Document>
            </motion.div>
          </AnimatePresence>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            <IconButton onClick={goToPrevPage} disabled={pageNumber <= 1 || !unlocked}>
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              Página {pageNumber} de {numPages}
            </Typography>
            <IconButton onClick={goToNextPage} disabled={pageNumber >= numPages || !unlocked}>
              <ArrowForwardIcon />
            </IconButton>
            <IconButton onClick={handleOpenDialog} disabled={!unlocked}>
              <FullscreenIcon />
            </IconButton>
          </Box>
        </StyledBox>
      </Container>
      <AsesorMeeting open={openAsesorMeeting} onClose={handleCloseAsesorMeeting} />
      <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
        <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            <AnimatePresence mode="wait">
              <motion.div
                key={pageNumber}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Document 
                  file="https://26887176.fs1.hubspotusercontent-eu1.net/hubfs/26887176/ZicoAsis.FilesAndMedia/informePrueba.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                  loading="Cargando informe..."
                  options={pdfOptions}
                >
                  <Page pageNumber={pageNumber} width={window.innerWidth} />
                </Document>
              </motion.div>
            </AnimatePresence>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
            <IconButton onClick={goToPrevPage} disabled={pageNumber <= 1}>
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              Página {pageNumber} de {numPages}
            </Typography>
            <IconButton onClick={goToNextPage} disabled={pageNumber >= numPages}>
              <ArrowForwardIcon />
            </IconButton>
            <IconButton onClick={handleCloseDialog} sx={{ ml: 2 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={openFormDialog} onClose={handleCloseFormDialog}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Generar informe de prueba
          </Typography>
          <Typography variant="body2" gutterBottom>
            Para poder generar un informe ficticio, necesitamos que nos compartas tu información para poder adecuarlo a tu corriente.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} id="zicoasis-generar-informe-form">
            <TextField
              label="Nombre*"
              labelId="name-label"
              fullWidth
              margin="normal"
              {...register('firstname', { required: true })}
            />
            <TextField
              label="Apellido"
              labelId="last-name-label"
              fullWidth
              margin="normal"
              {...register('lastname')}
            />
            <TextField
              label="Email*"
              fullWidth
              margin="normal"
              {...register('email', { required: true })}
            />
            <TextField
              label="Teléfono*"
              labelId="phone-label"
              fullWidth
              margin="normal"
              {...register('phone', { required: true })}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="corriente-label">Corriente*</InputLabel>
              <Select
                labelId="corriente-label"
                label="Corriente*"
                defaultValue=""
                {...register('corriente', { required: true })}
              >
                <MenuItem value="no-soy-psicologo">No soy psicólogo/a</MenuItem>
                <MenuItem value="cognitivo-conductual">Cognitivo-Conductual</MenuItem>
                <MenuItem value="terapia-aceptacion-compromiso">Terapia de Aceptación y Compromiso</MenuItem>
                <MenuItem value="terapia-dialéctica-conductual">Terapia Dialéctica Conductual</MenuItem>
                <MenuItem value="terapia-racional-emotiva">Terapia Racional Emotiva</MenuItem>
                <MenuItem value="psicoanalisis">Psicoanálisis</MenuItem>
                <MenuItem value="gestalt">Gestalt</MenuItem>
                <MenuItem value="sistémica">Sistémica</MenuItem>
                <MenuItem value="humanista">Humanista</MenuItem>
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Enviar y generar informe'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}